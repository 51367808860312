var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"verification overflow-auto position-relative h-100",class:{'overflow-hidden': _vm.pageLoading}},[_c('base-header',{attrs:{"title":_vm.$t('檢定'),"right-text":_vm.$t('我的成績單'),"left-arrow":true,"back-on-click-left":false,"is-title-bold":_vm.$isJungo,"mode":_vm.$device.isMobile ? 'secondary' : 'primary'},on:{"on-click-left":function($event){return _vm.$router.push({name: 'home'})},"on-click-right":_vm.onClick2Score,"on-click-close-pop":_vm.onClick2ClosePop}}),(_vm.pageLoading)?_c('loading-wrapper'):(_vm.isEmpty)?_c('EmptyPlaceholder',{staticClass:"empty",attrs:{"icon":'Verification',"text":_vm.$t('當前課程不開放檢定')}}):(_vm.verificationData.state === 'NOMORE')?_c('final',{on:{"on-click-to-score":_vm.onClick2Score,"on-click-close-pop":_vm.onClick2ClosePop}}):(
        _vm.verificationData.state !== 'SCORE' && _vm.verificationData.state !== 'OVER'
      )?_c('div',{staticClass:"verification-container scroll-bar"},[_c('banner',{on:{"on-click-to-score":_vm.onClick2Score,"on-click-close-pop":_vm.onClick2ClosePop}}),_c('div',{staticClass:"verification-content"},[(_vm.verificationData.state === 'DISABLE')?_c('condition'):_vm._e(),(_vm.verificationData.state === 'ENABLE')?_c('description'):_vm._e(),(
            _vm.verificationData.state === 'PLAYING' ||
            _vm.verificationData.state === 'WAITING' ||
            _vm.verificationData.state === 'WAITING_TO_PUBLISH'
          )?_c('verification-cards',{on:{"on-tsume-verificate-start":_vm.goTsumeVerification}}):_vm._e()],1)],1):_vm._e(),(_vm.$device.isMobile)?_c('milestone',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMilestoneShow),expression:"isMilestoneShow"}],on:{"close":_vm.closeMilestone}}):(_vm.isMilestoneShow)?_c('milestone-web-version',{on:{"close":_vm.closeMilestone}}):_vm._e(),(_vm.isCertificationShow)?_c('verification-certification',{on:{"close":_vm.closeCertification}}):_vm._e(),(_vm.isFailShow)?_c('modal-message-box',{attrs:{"result-content":_vm.$t('本次{rank}棋力檢定：未通過', {
          rank: _vm.ranksMapping[_vm.currentScore.rank].label,
        }),"has-btn-close":true,"buttons":_vm.failMessage},on:{"close":function($event){_vm.isFailShow = false},"on-item-click":_vm.onClick2CurrentScore}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }