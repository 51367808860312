<template>
  <div
    class="verification overflow-auto position-relative h-100"
    :class="{'overflow-hidden': pageLoading}"
  >
    <base-header
      :title="$t('檢定')"
      :right-text="$t('我的成績單')"
      :left-arrow="true"
      :back-on-click-left="false"
      :is-title-bold="$isJungo"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      @on-click-left="$router.push({name: 'home'})"
      @on-click-right="onClick2Score"
      @on-click-close-pop="onClick2ClosePop"
    ></base-header>
    <loading-wrapper v-if="pageLoading"></loading-wrapper>

    <EmptyPlaceholder
      v-else-if="isEmpty"
      class="empty"
      :icon="'Verification'"
      :text="$t('當前課程不開放檢定')"
    />
    <final
      v-else-if="verificationData.state === 'NOMORE'"
      @on-click-to-score="onClick2Score"
      @on-click-close-pop="onClick2ClosePop"
    >
    </final>
    <div
      v-else-if="
        verificationData.state !== 'SCORE' && verificationData.state !== 'OVER'
      "
      class="verification-container scroll-bar"
    >
      <banner
        @on-click-to-score="onClick2Score"
        @on-click-close-pop="onClick2ClosePop"
      ></banner>
      <div class="verification-content">
        <condition v-if="verificationData.state === 'DISABLE'"></condition>
        <description v-if="verificationData.state === 'ENABLE'"></description>

        <verification-cards
          v-if="
            verificationData.state === 'PLAYING' ||
            verificationData.state === 'WAITING' ||
            verificationData.state === 'WAITING_TO_PUBLISH'
          "
          @on-tsume-verificate-start="goTsumeVerification"
        ></verification-cards>
      </div>
    </div>

    <milestone
      v-if="$device.isMobile"
      v-show="isMilestoneShow"
      @close="closeMilestone"
    >
    </milestone>

    <milestone-web-version v-else-if="isMilestoneShow" @close="closeMilestone">
    </milestone-web-version>

    <verification-certification
      v-if="isCertificationShow"
      @close="closeCertification"
    >
    </verification-certification>

    <modal-message-box
      v-if="isFailShow"
      :result-content="
        $t('本次{rank}棋力檢定：未通過', {
          rank: ranksMapping[currentScore.rank].label,
        })
      "
      :has-btn-close="true"
      :buttons="failMessage"
      @close="isFailShow = false"
      @on-item-click="onClick2CurrentScore"
    >
    </modal-message-box>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Condition from '@/components/Verification/Condition.vue';
import Description from '@/components/Verification/Description.vue';
import VerificationCards from '@/components/Verification/VerificationCards.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import Final from '@/components/Verification/Final.vue';
import Milestone from '@/components/Verification/Milestone.vue';
import MilestoneWebVersion from '@/components/Verification/MilestoneWebVersion.vue';
import VerificationCertification from '@/components/Verification/VerificationCertification.vue';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import Banner from '@/components/Verification/Banner.vue';
import hjjService from '@/services/hjj';
import verificationService from '@/services/verification';
import ranksMapping from '@/json/rank.json';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import plansMixin from '@/mixin/plans';
import _ from 'lodash';

export default {
  components: {
    BaseHeader,
    Description,
    Condition,
    VerificationCards,
    LoadingWrapper,
    Final,
    Milestone,
    MilestoneWebVersion,
    VerificationCertification,
    Banner,
    ModalMessageBox,
    EmptyPlaceholder,
  },
  mixins: [plansMixin],
  data() {
    return {
      ranksMapping,
      pageLoading: false,
      isCertificationShow: false,
      isFailShow: false,
      failMessage: [
        {
          variant: this.$isJungo ? 'secondary' : 'primary',
          value: 'confirm',
          content: this.$t('查看成績單'),
        },
      ],
    };
  },
  computed: {
    isEmpty() {
      return _.isEmpty(this.verificationData);
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    currentScore() {
      return this.$store.getters['verification/currentScore'];
    },
    pastScores() {
      return this.$store.getters['verification/pastScores'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    isMilestoneShow() {
      return this.$store.state.verification.isMilestoneShow;
    },
  },
  async created() {
    if (this.verificationData.state !== 'NOMORE') {
      try {
        this.pageLoading = true;

        await this.$store.dispatch(
          'verification/getVerification',
          this.currentPlan.id
        );

        if (this.verificationData.state === 'SCORE') {
          await this.passScore();
        }
        this.pageLoading = false;
      } catch (error) {
        this.pageLoading = false;
      }
    }
    this.$nextTick(() => {
      this.getNewScores();
    });
    const videoData = await hjjService.getCourseCompletionVideoAuth(
      this.videoId
    );
    this.$store.commit(
      'verification/setMilestoneVideoCover',
      videoData.VideoMeta.CoverURL
    );
  },
  methods: {
    async getNewScores() {
      await this.$store.dispatch('verification/getTotalScores');
      const hasRemindNewScore = JSON.parse(getLocalData('has-new-score'));

      if (
        (hasRemindNewScore == null || hasRemindNewScore) &&
        this.pastScores.length !== 0
      ) {
        this.$store.commit('verification/setHasNewScore', true);
      }
    },
    async passScore() {
      try {
        if (this.verificationData.isPass) {
          await verificationService.getCertification(
            this.verificationData.rank,
            this.verificationData.id,
            this.currentPlan.id
          );
        }
        await verificationService.postOver(
          this.verificationData.rank,
          this.verificationData.id,
          this.currentPlan.id
        );

        await this.$store.dispatch(
          'verification/getVerification',
          this.currentPlan.id
        );
        saveLocalData('has-new-score', true);
        this.$store.commit('verification/setHasNewScore', true);
        setTimeout(() => {
          if (this.currentScore.isPass) {
            this.isCertificationShow = true;
          } else {
            this.isFailShow = true;
          }
        }, 500);
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      }
    },
    onClick2CurrentScore() {
      saveLocalData('has-new-score', false);
      this.$router.push(`/verification/pass-scores/${this.currentScore.id}`);
    },
    closeCertification() {
      if (this.isPlan30k_10k && this.verificationData.state === 'NOMORE') {
        this.$store.commit('verification/setMilestone', true);
      }
      this.isCertificationShow = false;
    },
    closeMilestone() {
      this.$store.commit('verification/setMilestone', false);
    },
    onClick2Score() {
      this.$store.commit('verification/setHasNewScore', false);
      saveLocalData('has-new-score', false);
      this.$router.push({name: 'past-scores'});
    },
    onClick2ClosePop() {
      this.$store.commit('verification/setHasNewScore', false);
      saveLocalData('has-new-score', false);
    },
    async goTsumeVerification(tsume) {
      this.$store.commit('tsumeGame/setVerificationTsume', tsume);
      let overTime = 0;

      if (tsume.overTime == null) {
        this.$store.commit('verification/setCurrentVerificationTsume', {
          rank: this.verificationData.rank,
          id: this.verificationData.id,
          type: tsume.type,
        });
        overTime = 0;
      } else if (tsume.overTime - Date.now() < 0) {
        this.$Message.warning(this.$t('該項目作答時間已到'));
        this.$store.dispatch(
          'verification/getVerification',
          this.currentPlan.id
        );
        return;
      }

      return this.$router.push({
        name: 'verificationTsume',
        params: {
          id: this.verificationData.id,
          type: tsume.type,
          overTime: tsume.overTime ? tsume.overTime : overTime,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-container {
  overflow: overlay;
  height: calc(100% - 56px);
  .verification-content {
    padding: 16px;
  }
}
.empty {
  height: calc(100% - 56px) !important;
  .icon-Verification {
    color: rgba($font-grayscale-3, 0.5);
    font-size: 150px;
  }
}

@media screen and (min-width: 768px) {
  .verification-container {
    height: calc(100% - 80px);
  }
  .verification-content {
    padding-top: 24px;
    padding-bottom: 40px;
    max-width: 720px;
    margin: 0 auto;
  }
  .empty {
    height: calc(100% - 80px) !important;
  }
}

@media screen and (min-width: 1200px) {
  .verification-content {
    max-width: 1140px;
  }
}
</style>
