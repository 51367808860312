<template>
  <div class="conditions bg-bgsection p-2">
    <div
      class="condition-title bg-secondary text-white font-weight-bold text-18"
    >
      {{ $t('下列條件滿足後開放檢定') }} ：
    </div>
    <div
      v-for="(condition, index) in conditions"
      :key="condition.configType"
      class="condition position-relative bg-white mt-2 d-flex align-items-center"
    >
      <div>
        <div
          v-if="condition.isLock"
          class="list-num text-white d-flex align-items-center justify-content-center"
          :class="$isJungo ? 'bg-primary' : 'bg-secondary'"
        >
          {{ index + 1 }}
        </div>
        <span
          v-else
          class="icon-Check d-block text-white text-24 bg-positive d-flex align-items-center justify-content-center"
        >
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <p>{{ getConditionText(condition) }}</p>
        <p
          v-if="condition.configType === 'star' && condition.isLock"
          class="text-primary font-weight-bold"
        >
          ({{ condition.userStarCount }}/{{ condition.starCount }})
        </p>
      </div>

      <div v-if="condition.isLock" class="progress-line position-absolute">
        <div
          class="achieve-line bg-primary h-100"
          :style="getProgressStyle(condition)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    conditions() {
      return this.verificationData.conditions;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    getProgressStyle(condition) {
      let width = 0;
      switch (condition.configType) {
        case 'practice':
          width = (condition.userPassId / condition.aiId) * 100;
          break;
        case 'star':
          width = (condition.userStarCount / condition.starCount) * 100;
          break;
        case 'time':
          width = (condition.diffDay / condition.day) * 100;
          break;
      }

      return {width: `${width}%`};
    },
    getConditionText(condition) {
      let text = '';
      switch (condition.configType) {
        case 'practice':
          text = this.$t('通關 AI 對弈 Lv.{aiLevel}', {
            aiLevel: condition.aiId,
          });
          break;
        case 'star':
          text = this.$t('學習進度星星達 {count} 顆', {
            count: condition.starCount,
          });
          break;
        case 'time':
          const newDate = this.$day()
            .subtract(condition.diffDay, 'day')
            .add(condition.day, 'day');
          text = `${newDate.format('YYYY-MM-DD')} ${condition.time}`;
          break;
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.conditions {
  border-radius: 10px;
  .condition-title {
    padding: 10px 16px;
    border-radius: 10px;
    line-height: 30px;
  }
  .condition {
    padding: 14px 24px;
    border-radius: 10px;
    .list-num {
      border-radius: 100%;
      width: 30px;
      height: 30px;
    }
    .icon-Check {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    p {
      margin-bottom: 0;
      margin-left: 24px;
    }
    .progress-line {
      height: 2px;
      bottom: 0;
      left: 9px;
      width: calc(100% - 18px);
      background: $grayscale-gray;
      border-radius: 10px;
    }
    .achieve-line {
      border-radius: 10px;
    }
  }
}
</style>
