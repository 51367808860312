<template>
  <div class="description bg-white">
    <div>
      <div
        class="title bg-bgsection text-18 font-weight-bold"
        :class="$isJungo ? 'text-secondary' : 'text-primary'"
      >
        {{ $t('檢定說明') }}：
      </div>
      <ol class="mb-0 pl-4">
        <li>
          {{ $t('棋力檢定包含')
          }}<span class="text-primary">{{ itemsText.text }}</span
          >{{ itemsText.itemsLength > 1 ? $t('等') : ''
          }}{{ $t('{count}個項目。', {count: itemsText.itemsLength}) }}
        </li>
        <li>{{ $t('各項目皆需在規定時間內完成作答。') }}</li>
        <li>
          {{ $t('完成所有項目後，成績會')
          }}<span class="text-primary">{{ $t('立即') }}</span
          >{{ $t('公布') }}。
        </li>
        <li>
          {{ $t('分數達評分標準即通過測驗 , 將升級個人棋力並授予證書。') }}
        </li>
      </ol>
    </div>
    <div class="rule-wrapper">
      <div
        class="title bg-bgsection text-18 mb-2 font-weight-bold"
        :class="$isJungo ? 'text-secondary' : 'text-primary'"
      >
        {{ $t('評分標準') }}：
      </div>
      <ol class="mb-0 pl-4">
        <li v-for="tsume in sortedTsume" :key="`tsume${tsume.sort}`">
          {{ tsume.type }} :
          {{ $t('測驗時間{time}分鐘', {time: tsume.time / 60 / 1000}) }} ,
          {{ $t('共{count}題', {count: tsume.count}) }} ,
          {{ $t('每題{score}分', {score: tsume.score / tsume.count}) }}。
        </li>
        <li v-if="gameText">
          {{ $t('下棋') }} : {{ $t('計分項目為') }}{{ gameText }}。
        </li>
        <li>
          {{ $t('所有項目加總滿分為100分') }} , {{ $t('得分超過')
          }}{{ verificationData.passScore }}{{ $t('以上')
          }}{{ $t('視為通過') }}。
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import rankList from '@/json/rank.json';
import plansMixin from '@/mixin/plans';

export default {
  mixins: [plansMixin],
  data() {
    return {
      rankList,
      sortedTsume: [],
    };
  },

  computed: {
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    level() {
      if (this.verificationData.rank) {
        return this.rankList.find((rank) => {
          return rank.value === this.verificationData.rank;
        }).label;
      } else {
        return '30k';
      }
    },
    gameText() {
      const textArray = [];
      if (this.verificationData.game.vision > 0) {
        textArray.push(
          `${this.$t('大局')}${this.verificationData.game.vision}${this.$t(
            '分'
          )}`
        );
      }
      if (this.verificationData.game.fuseki > 0) {
        textArray.push(
          `${this.$t('佈局')}${this.verificationData.game.fuseki}${this.$t(
            '分'
          )}`
        );
      }
      if (this.verificationData.game.fight > 0) {
        textArray.push(
          `${this.$t('戰鬥')}${this.verificationData.game.fight}${this.$t(
            '分'
          )}`
        );
      }
      if (this.verificationData.game.endgame > 0) {
        textArray.push(
          `${this.$t('官子')}${this.verificationData.game.endgame}${this.$t(
            '分'
          )}`
        );
      }
      if (this.verificationData.game.comprehensive > 0) {
        textArray.push(
          `${this.$t('綜合')}${
            this.verificationData.game.comprehensive
          }${this.$t('分')}`
        );
      }
      if (this.verificationData.game.win > 0) {
        textArray.push(
          `${this.$t('勝負')}${this.verificationData.game.win}${this.$t('分')}`
        );
      }
      if (this.verificationData.game.goodRate > 0) {
        textArray.push(
          `${this.$t('好棋率')}${this.verificationData.game.goodRate}${this.$t(
            '分'
          )}`
        );
      }
      return textArray.join('、');
    },
    itemsText() {
      const NumsEnum = Object.freeze({
        0: '零',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        7: '七',
        8: '八',
        9: '九',
        10: '十',
      });

      const itemsArray = [];

      this.sortedTsume.forEach((tsume) => {
        itemsArray.push(tsume.type);
      });

      if (this.verificationData.game.list.length !== 0) {
        itemsArray.push(this.$t('下棋'));
      }
      return {
        text: itemsArray.join('、'),
        itemsLength:
          NumsEnum[
            this.verificationData.tsume.length +
              this.verificationData.game.list.length
          ],
      };
    },
  },
  created() {
    if (this.verificationData.tsume != null) {
      this.sortedTsume = this.verificationData.tsume.sort(
        (a, b) => a.sort - b.sort
      );
      this.sortedTsume = this.sortedTsume.map((tsume) => {
        return tsume;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.description {
  border-radius: 10px;
  box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
  padding: 16px 24px;
  .title {
    line-height: 30px;
    border-radius: 10px;
    padding: 5px 16px;
    margin-bottom: 8px;
  }
  .rule-wrapper {
    margin-top: 24px;
  }
  li {
    line-height: 28px;
  }
}

@media screen and (min-width: 768px) {
  .description {
    padding: 24px;
    .title {
      margin-bottom: 16px;
    }
    .rule-wrapper {
      margin-top: 48px;
    }
    li {
      font-size: 18px;
      line-height: 30px;
    }
  }
}
</style>
