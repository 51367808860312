<template>
  <div id="verification-banner" class="overflow-auto verification-banner">
    <div class="bg-wrapper position-relative">
      <div class="score-btn position-absolute">
        <b-button
          v-if="!$device.isMobile"
          id="my-scores-btn"
          size="md"
          variant="primary"
          pill
          @click="onClick2Scores"
        >
          {{ $t('我的成績單') }}
        </b-button>
        <div class="locate"></div>
        <b-popover
          triggers="click"
          target="my-scores-btn"
          placement="bottom"
          custom-class="hjj-popover"
          container="verification-banner"
          :show="hasNewScore"
          >{{ $t('你有新的成績') }}
          <i class="icon-X text-16 ml-2" @click="onClick2ClosePop"></i>
        </b-popover>
      </div>
      <div
        class="brand-wrap text-white position-relative text-center font-weight-bold"
      >
        <img
          :srcset="
            require('@/assets/img/verification/verification-shield.png?srcset')
          "
        />
        <div class="level position-absolute">{{ level }}</div>
        <p class="brand-title m-0 position-absolute">{{ $t('棋力檢定') }}</p>
      </div>
      <div
        class="status-wrap text-center text-20 font-weight-bold"
        :class="$isJungo ? 'text-secondary' : 'text-accent'"
      >
        <b-button
          v-if="['DISABLE', 'ENABLE'].includes(verificationData.state)"
          class="btn-start"
          size="lg"
          variant="primary"
          :disabled="verificationData.state === 'DISABLE'"
          @click="startVerification"
        >
          {{ hasMapSameRankRecord ? $t('再次檢定') : $t('開始檢定') }}
        </b-button>
        <p
          v-if="verificationData.state === 'PLAYING'"
          class="banner-playing-text m-0"
        >
          {{ $t('檢定進行中') }}
        </p>
        <p
          v-if="
            verificationData.state === 'WAITING' ||
            verificationData.state === 'WAITING_TO_PUBLISH'
          "
          class="announce-text m-0"
        >
          {{
            isPlanKid2week
              ? $t('成績將於5分鐘後公布')
              : $t('成績將於隔日早上10點公布')
          }}
        </p>
        <div
          v-if="
            verificationData.state === 'WAITING' ||
            verificationData.state === 'WAITING_TO_PUBLISH'
          "
          class="subText text-primary m-0"
          :class="{'text-secondary': $isJungo && !$device.isMobile}"
        >
          {{ $t('你已完成所有項目') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import verificationService from '@/services/verification';
import plansMixin from '@/mixin/plans';
import rankList from '@/json/rank.json';
import day from 'dayjs';

export default {
  mixins: [plansMixin],
  data() {
    return {
      rankList,
      day,
    };
  },
  computed: {
    hasNewScore() {
      return this.$store.getters['verification/hasNewScore'];
    },
    hasMapSameRankRecord() {
      return this.pastScores.some((score) => {
        return score.rank === this.verificationData.rank;
      });
    },
    pastScores() {
      return this.$store.getters['verification/pastScores'];
    },
    level() {
      if (this.verificationData.rank) {
        return this.rankList.find((rank) => {
          return rank.value === this.verificationData.rank;
        }).label;
      } else {
        return '30k';
      }
    },
    openDay() {
      const openDayData = this.verificationData.conditions.find(
        (condition) => condition.coursePlan === this.currentPlan.id
      );

      const newDate = this.$day(this.currentPlan.startedAt).add(
        openDayData.day - 1,
        'day'
      );

      return `${day(newDate).format('YYYY-MM-DD')} ${openDayData.time}`;
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  methods: {
    startVerification() {
      if (!this.isLoading) {
        this.$store.commit('env/setIsLoading', true);
        verificationService
          .startVerification(this.verificationData.rank, this.currentPlan.id)
          .then(() => {
            this.$store.dispatch(
              'verification/getVerification',
              this.currentPlan.id
            );
          })
          .catch(() => {
            this.$Message.warning(`${this.$t('請再試一次')}`);
          })
          .finally(() => {
            this.$store.commit('env/setIsLoading', false);
          });
      }
    },
    onClick2Scores() {
      this.$emit('on-click-to-score');
    },
    onClick2ClosePop() {
      this.$emit('on-click-close-pop');
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-banner {
  margin: 0 auto;
}
.bg-wrapper {
  padding-top: 16px;
  background: $bgsection;
}
.score-btn {
  z-index: 5;
  top: 16px;
  right: 21px;
  .icon-X {
    color: $font-grayscale-3;
  }
}
.brand-wrap {
  img {
    width: 220px;
  }
  .level {
    font-size: 24.6px;
    top: 98px;
    left: 0;
    right: 0;
  }
  .brand-title {
    font-size: 15.8px;
    bottom: 46.6px;
    left: 0;
    right: 0;
  }
}
.status-wrap {
  .banner-start-time {
    font-size: 20px;
    line-height: 34px;
  }
  .banner-start-text {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
  }
  .banner-playing-text {
    font-size: 20px;
    line-height: 34px;
    padding-bottom: 30px;
  }
  .btn-start {
    width: 240px;
    margin-bottom: 16px;
  }
  .announce-text {
    font-size: 20px;
    line-height: 34px;
  }
  .subText {
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .verification-banner {
    max-width: 720px;
    margin-top: 24px;
    padding: 0 16px;
  }
  .bg-wrapper {
    box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
    border-radius: 10px;
  }
  .brand-wrap {
    img {
      width: 300px;
    }
    .level {
      font-size: 36px;
      top: 135.48px;
    }
    .brand-title {
      font-size: 28px;
      bottom: 60px;
    }
  }
  .status-wrap {
    .banner-start-time {
      font-size: 28px;
      line-height: 44px;
    }
    .banner-start-text {
      font-size: 18px;
      line-height: 30px;
      padding-bottom: 10px;
    }
    .banner-playing-text {
      font-size: 28px;
      line-height: 44px;
      padding-bottom: 24px;
    }
    .btn-start {
      margin-bottom: 20px;
    }
    .announce-text {
      font-size: 18px;
      line-height: 30px;
    }
    .subText {
      font-size: 16px;
      line-height: 28px;
      font-weight: normal;
      padding-bottom: 26px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .verification-banner {
    max-width: 1140px;
  }
}
</style>
