<template>
  <div class="final scroll-bar">
    <div
      class="final-container px-3 position-relative h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <b-button
        v-if="!$device.isMobile"
        id="my-scores-btn"
        size="md"
        variant="primary"
        pill
        class="score-btn position-absolute"
        @click="onClick2Scores"
      >
        {{ $t('我的成績單') }}
      </b-button>
      <b-popover
        triggers="click"
        target="my-scores-btn"
        placement="bottom"
        custom-class="hjj-popover"
        container="verification-banner"
        :show="hasNewScore"
        >{{ $t('你有新的成績') }}
        <i class="icon-X text-16 ml-2" @click="onClick2ClosePop"></i>
      </b-popover>
      <div class="final-img-wrap text-center position-relative mb-3">
        <p
          class="title position-absolute font-weight-normal"
          :style="[imgTextStyle, titleStyle]"
        >
          {{ $t('黑嘉嘉圍棋教室') }}
        </p>
        <img
          class="img-fluid"
          :srcset="require('@/assets/img/verification/final-laurel.png?srcset')"
        />
        <p
          class="date position-absolute m-0"
          :style="[imgTextStyle, dateStyle]"
        >
          {{ date }}
        </p>
      </div>

      <h3 :class="$isJungo ? 'text-secondary' : 'text-primary'">
        {{
          mapContent[verificationData.rank].title ||
          $t('恭喜你通過 {rank} 檢定！', {
            rank: rankList[verificationData.rank]?.label,
          })
        }}
      </h3>
      <p v-if="hasCoupon" class="content text-14">
        {{ content }}
      </p>
      <b-button
        v-if="hasCoupon"
        variant="primary"
        size="md"
        class="coupon-btn"
        @click="onClickAction"
      >
        {{ $t('前往了解') }}
      </b-button>
      <b-button
        v-if="isPlan30k_10k"
        variant="secondary"
        size="md"
        class="milestone-btn"
        @click="onClickShowMilestone"
      >
        {{ $t('查看完課特輯') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import day from 'dayjs';
import plansMixin from '@/mixin/plans';
import rankList from '@/json/rank.json';
import {Browser} from '@capacitor/browser';

export default {
  mixins: [plansMixin],
  data() {
    return {
      rankList,
    };
  },
  computed: {
    hasCoupon() {
      return this.$day(this.verificationData?.updatedAt).isAfter(
        '2022/11/08 00:00'
      );
    },
    mapContent() {
      return {
        0: {
          /* eslint-disable indent */
          content: this.$day().isAfter('2022/12/04 00:00')
            ? this.$t(
                '真是太棒了，你已經成為小小棋士囉～想要和其他小朋友一起下棋嗎？快來看看家裡附近有哪些圍棋教室！'
              )
            : this.$t(
                '圍棋宇宙還有很多好玩、有趣的課程等著你來探索哦！系統將提供專屬折扣，快去看看！'
              ),
        },
        5: {
          content: this.$t(
            '繼續跟著黑咩、白咩一起，向歸一爺爺學習更多的圍棋知識吧～系統將提供專屬折扣，快去看看！'
          ),
        },
        10: {
          content: this.$t(
            '你已經跨越初學者階段，準備好進入19路大棋盤了嗎～系統將提供專屬折扣，快去看看！'
          ),
        },
        15: {
          content: this.$t(
            '持之以恆的學習，就能得到滿滿的收穫！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！'
          ),
        },
        20: {
          content: this.$t(
            '太厲害了，你已達成重要的里程碑—通過10級檢定！系統將提供專屬折扣，快去看看！'
          ),
        },
        25: {
          content: this.$t(
            '此刻你已掌握19x19宇宙的多數觀念，能在每次對弈中獲得無窮的樂趣！系統將提供專屬折扣，快去看看！'
          ),
        },
        27: {
          content: this.$t(
            '我們又往「初段」的路上更邁進一步了！為了鼓勵你繼續加油，系統將提供專屬折扣，快去看看！'
          ),
        },
        30: {
          title: this.$t('＼棋味無窮 初段成就達成／'),
          content: this.$t(
            '太棒了，你已完成重大成就—通過初段檢定！系統將提供限時折扣金500元，期待與你在通往神乎棋技的道路上再次相見！'
          ),
        },
      };
    },
    hasNewScore() {
      return this.$store.getters['verification/hasNewScore'];
    },
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    date() {
      if (this.verificationData.updatedAt != null) {
        return day(this.verificationData.updatedAt).format('YYYY-MM-DD');
      } else {
        return day().format('YYYY-MM-DD');
      }
    },
    imgTextStyle() {
      return {
        fontSize: `${((this.width - 36.7336) * 0.0473).toFixed(2)}px`,
      };
    },
    titleStyle() {
      return {
        top: `${((this.width - 60.7698) * 0.1273).toFixed(2)}px`,
      };
    },
    dateStyle() {
      return {
        bottom: `${((this.width - 57.1638) * 0.0818).toFixed(2)}px`,
      };
    },
    width() {
      return this.$store.state.env.width;
    },
    content() {
      return (
        this.mapContent[this.verificationData.rank]?.content ||
        this.mapContent[15]?.content
      );
    },
  },
  methods: {
    onClick2Scores() {
      this.$emit('on-click-to-score');
    },
    onClick2ClosePop() {
      this.$emit('on-click-close-pop');
    },
    onClickShowMilestone() {
      this.$store.commit('verification/setMilestone', true);
    },
    onClickAction() {
      if (this.isPlanKid2week && this.$day().isAfter('2022/12/04 00:00')) {
        Browser.open({
          url: 'https://forms.gle/nSNcvGa7nP5fL6Kh6',
          presentationStyle: 'popover',
        });
      } else {
        this.$router.push({name: 'coupon'});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.final {
  height: calc(100% - 56px);
  overflow: overlay;
  .final-container {
    color: #ff855e;
    background: $bgsection;
  }
  .final-img-wrap {
    .title {
      margin: 0;
      left: 0;
      right: 0;
    }
    .date {
      left: 0;
      right: 0;
    }
  }
  .milestone-btn,
  .coupon-btn {
    margin-top: 14px;
    width: 250px;
  }
  .score-btn {
    top: 16px;
    right: 16px;
  }
  .content {
    margin: 14px 0 0;
    width: 250px;
    color: $font-grayscale-1;
    line-height: 22px;
  }
}

@media screen and (min-width: 768px) {
  .final {
    max-width: 720px;
    margin: 0 auto;
    padding: 25px 16px 40px;
    height: calc(100% - 80px);
    .final-container {
      border-radius: 10px;
    }
    .final-img-wrap {
      width: 500px;
      .title {
        line-height: 43px;
        font-size: 24px !important;
        top: 51.72px !important;
      }
      .date {
        line-height: 43px;
        font-size: 24.8224px !important;
        bottom: 29.78px !important;
      }
    }
    h3 {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .final {
    max-width: 1140px;
  }
}
</style>
