var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.verificationData.tsume != null)?_c('div',{staticClass:"verification-cards",class:{'jungo-verification-cards': _vm.$isJungo}},_vm._l((_vm.questions),function(question,index){return _c('div',{key:`${question.category}${index}`,staticClass:"verification-card d-flex justify-content-between align-items-center border-0",class:[`${question.category}${index}`]},[_c('div',{staticClass:"card-left d-flex align-items-center"},[(!_vm.$device.isMobile)?_c('div',{staticClass:"card-no d-flex align-items-center justify-content-center mr-3",class:_vm.$isJungo ? 'bg-primary' : 'bg-secondary'},[_vm._v(" "+_vm._s(index + 1)+" ")]):_vm._e(),_c('div',{staticClass:"card-title text-truncate d-flex flex-column justify-content-center"},[_c('h4',{staticClass:"text-truncate",class:_vm.$device.isMobile ? '' : 'font-weight-bold'},[(question.category === 'tsume')?_c('span',[_vm._v(" "+_vm._s(question.type)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('下棋'))+_vm._s(question.index)+" ")])]),(question.category === 'tsume')?_c('p',{staticClass:"text-12"},[_vm._v(" "+_vm._s(_vm.$t('測驗時間{time}分鐘', {time: _vm.convert2Minutes(question.time)}))+" , "+_vm._s(_vm.$t('共{count}題', {count: question.count}))+" ")]):_vm._e()])]),_c('div',[(question.state === 'OVER')?_c('p',{staticClass:"m-0 completed pr-2",class:_vm.$device.isMobile ? '' : 'font-weight-bold'},[_vm._v(" "+_vm._s(_vm.$t('已完成'))+" ")]):_c('b-button',{attrs:{"size":"lg","variant":(!_vm.$isJungo && _vm.$device.isMobile) || (_vm.$isJungo && !_vm.$device.isMobile)
              ? 'secondary'
              : 'primary',"disabled":question.state === 'DISABLE'},on:{"click":function($event){return _vm.onClickVerification(question.category, question)}}},[(
              (question.category === 'game' && question.state === 'ENABLE') ||
              (question.category === 'tsume' &&
                question.state === 'ENABLE' &&
                question.overTime === null) ||
              question.state === 'DISABLE'
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$device.isMobile ? _vm.$t('開始') : _vm.$t('開始檢定'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.$t('繼續')))])])],1)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }