<template>
  <div
    v-if="verificationData.tsume != null"
    class="verification-cards"
    :class="{'jungo-verification-cards': $isJungo}"
  >
    <div
      v-for="(question, index) in questions"
      :key="`${question.category}${index}`"
      class="verification-card d-flex justify-content-between align-items-center border-0"
      :class="[`${question.category}${index}`]"
    >
      <div class="card-left d-flex align-items-center">
        <div
          v-if="!$device.isMobile"
          class="card-no d-flex align-items-center justify-content-center mr-3"
          :class="$isJungo ? 'bg-primary' : 'bg-secondary'"
        >
          {{ index + 1 }}
        </div>
        <div
          class="card-title text-truncate d-flex flex-column justify-content-center"
        >
          <h4
            class="text-truncate"
            :class="$device.isMobile ? '' : 'font-weight-bold'"
          >
            <span v-if="question.category === 'tsume'">
              {{ question.type }}
            </span>
            <span v-else> {{ $t('下棋') }}{{ question.index }} </span>
          </h4>
          <p v-if="question.category === 'tsume'" class="text-12">
            {{
              $t('測驗時間{time}分鐘', {time: convert2Minutes(question.time)})
            }}
            ,
            {{ $t('共{count}題', {count: question.count}) }}
          </p>
        </div>
      </div>

      <div>
        <p
          v-if="question.state === 'OVER'"
          class="m-0 completed pr-2"
          :class="$device.isMobile ? '' : 'font-weight-bold'"
        >
          {{ $t('已完成') }}
        </p>
        <b-button
          v-else
          size="lg"
          :variant="
            (!$isJungo && $device.isMobile) || ($isJungo && !$device.isMobile)
              ? 'secondary'
              : 'primary'
          "
          :disabled="question.state === 'DISABLE'"
          @click="onClickVerification(question.category, question)"
        >
          <span
            v-if="
              (question.category === 'game' && question.state === 'ENABLE') ||
              (question.category === 'tsume' &&
                question.state === 'ENABLE' &&
                question.overTime === null) ||
              question.state === 'DISABLE'
            "
          >
            {{ $device.isMobile ? $t('開始') : $t('開始檢定') }}
          </span>
          <span v-else>{{ $t('繼續') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import rankList from '@/json/rank.json';
import day from 'dayjs';

export default {
  data() {
    return {
      rankList,
    };
  },
  computed: {
    verificationData() {
      return this.$store.getters['verification/verificationData'] || {};
    },
    questions() {
      const tsume = this.verificationData.tsume.map((tsume) => {
        return {
          ...tsume,
          category: 'tsume',
        };
      });
      const game = this.verificationData.game.list.map((game) => {
        return {
          ...game,
          category: 'game',
        };
      });
      const list = [...tsume, ...game];
      list.sort((a, b) => a.sort - b.sort);
      let index = 0;
      return list.map((question) => {
        if (question.category === 'tsume') {
          return question;
        } else {
          index += 1;
          return {
            ...question,
            index,
          };
        }
      });
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  methods: {
    convert2Minutes(time) {
      return day(time).format('m');
    },
    onClickVerification(category, question) {
      if (category === 'tsume') {
        this.tsumeVerificate(question);
      } else {
        this.gameVerificate(question);
      }
    },
    gameVerificate(gameData) {
      this.$store.commit('env/setIsLoading', true);
      const gameDate = {
        rank: this.verificationData.rank,
        verificationId: this.verificationData.id,
        gameId: gameData.id,
        coursePlan: this.currentPlan.id,
      };
      const actionApi =
        gameData.state === 'PLAYING'
          ? 'getUnfinishedVerificationGame'
          : 'createVerificationGame';

      this.$store
        .dispatch(`aiGame/${actionApi}`, gameDate)
        .then(() => {
          return this.$router.push({
            path: `/verification/game/${encodeURIComponent(
              this.verificationData.id
            )}/${gameData.id}`,
          });
        })
        .catch(() => {
          this.$Message.warning(`${this.$t('請再試一次')}`);
        })
        .finally(() => {
          this.$store.commit('env/setIsLoading', false);
        });
    },
    tsumeVerificate(tsume) {
      this.$emit('on-tsume-verificate-start', tsume);
    },
  },
};
</script>

<style lang="scss" scoped>
.verification-cards {
  border-radius: 10px;
  padding: 16px;
  background: white;
  .verification-card {
    background: #fcf6ee;
    border-radius: 10px;
    padding: 0 16px 0 24px;
    height: 79px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    .card-left {
      width: 70%;
    }
    .card-no {
      width: 80px;
      height: 80px;
      color: $white;
      font-size: 28px;
      border-radius: 10px;
    }
    .card-title {
      p {
        color: $font-grayscale-2;
        margin: 0;
        margin-top: 4px;
      }
    }

    .completed {
      color: $font-grayscale-3;
    }
  }
  &.jungo-verification-cards {
    .verification-card {
      background: $bgsection;
    }
    .completed {
      color: $secondary;
    }
  }
}

@media screen and (min-width: 768px) {
  .verification-cards {
    padding: 0;
    background: none;
    .verification-card {
      background: $white;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
      padding: 0 24px;
      height: 128px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .card-title {
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
          margin-top: 8px;
        }
      }

      .completed {
        font-size: 20px;
      }
    }
    &.jungo-verification-cards {
      .verification-card {
        background: $white;
      }
    }
  }
}
</style>
