var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-auto verification-banner",attrs:{"id":"verification-banner"}},[_c('div',{staticClass:"bg-wrapper position-relative"},[_c('div',{staticClass:"score-btn position-absolute"},[(!_vm.$device.isMobile)?_c('b-button',{attrs:{"id":"my-scores-btn","size":"md","variant":"primary","pill":""},on:{"click":_vm.onClick2Scores}},[_vm._v(" "+_vm._s(_vm.$t('我的成績單'))+" ")]):_vm._e(),_c('div',{staticClass:"locate"}),_c('b-popover',{attrs:{"triggers":"click","target":"my-scores-btn","placement":"bottom","custom-class":"hjj-popover","container":"verification-banner","show":_vm.hasNewScore}},[_vm._v(_vm._s(_vm.$t('你有新的成績'))+" "),_c('i',{staticClass:"icon-X text-16 ml-2",on:{"click":_vm.onClick2ClosePop}})])],1),_c('div',{staticClass:"brand-wrap text-white position-relative text-center font-weight-bold"},[_c('img',{attrs:{"srcset":require('@/assets/img/verification/verification-shield.png?srcset')}}),_c('div',{staticClass:"level position-absolute"},[_vm._v(_vm._s(_vm.level))]),_c('p',{staticClass:"brand-title m-0 position-absolute"},[_vm._v(_vm._s(_vm.$t('棋力檢定')))])]),_c('div',{staticClass:"status-wrap text-center text-20 font-weight-bold",class:_vm.$isJungo ? 'text-secondary' : 'text-accent'},[(['DISABLE', 'ENABLE'].includes(_vm.verificationData.state))?_c('b-button',{staticClass:"btn-start",attrs:{"size":"lg","variant":"primary","disabled":_vm.verificationData.state === 'DISABLE'},on:{"click":_vm.startVerification}},[_vm._v(" "+_vm._s(_vm.hasMapSameRankRecord ? _vm.$t('再次檢定') : _vm.$t('開始檢定'))+" ")]):_vm._e(),(_vm.verificationData.state === 'PLAYING')?_c('p',{staticClass:"banner-playing-text m-0"},[_vm._v(" "+_vm._s(_vm.$t('檢定進行中'))+" ")]):_vm._e(),(
            _vm.verificationData.state === 'WAITING' ||
            _vm.verificationData.state === 'WAITING_TO_PUBLISH'
          )?_c('p',{staticClass:"announce-text m-0"},[_vm._v(" "+_vm._s(_vm.isPlanKid2week ? _vm.$t('成績將於5分鐘後公布') : _vm.$t('成績將於隔日早上10點公布'))+" ")]):_vm._e(),(
            _vm.verificationData.state === 'WAITING' ||
            _vm.verificationData.state === 'WAITING_TO_PUBLISH'
          )?_c('div',{staticClass:"subText text-primary m-0",class:{'text-secondary': _vm.$isJungo && !_vm.$device.isMobile}},[_vm._v(" "+_vm._s(_vm.$t('你已完成所有項目'))+" ")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }