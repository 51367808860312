<template>
  <div class="milestone h-100 overflow-hidden">
    <a
      class="close-btn position-absolute text-32 text-white"
      href="#"
      @click.prevent="close"
    >
      <i class="icon-X"></i>
    </a>
    <img
      class="img-fluid"
      :srcset="require('@/assets/img/verification/bg-milestone.png')"
      alt=""
    />
    <div
      class="content w-100 h-100 px-3 py-4 position-absolute d-flex flex-column justify-content-center"
    >
      <p class="text-20 text-white text-center font-weight-bold mb-2">
        {{ $t('恭喜你完成所有課程！') }}
        <br />
        {{ $t('立即觀賞完課特輯') }}
      </p>
      <img
        class="img-fluid"
        :srcset="require('@/assets/img/verification/cheer.png?srcset')"
      />
      <div class="video-wrap bg-white p-2">
        <div
          class="video-bg position-relative h-100 overflow-hidden d-flex align-items-center justify-content-center"
          @click="isMileStoneVideoShow = true"
        >
          <div class="video-mask position-absolute w-100 h-100"></div>
          <img
            v-if="coverUrl"
            class="video-cover img-fluid w-100 h-100"
            :src="coverUrl"
            alt=""
          />
          <div
            class="video-btn position-absolute bg-accent text-white d-flex align-items-center justify-content-center"
          >
            <i class="icon-Play"></i>
          </div>
        </div>
      </div>
      <div class="inform text-white text-center p-2 mt-2">
        {{ $t('完課了還是可以自己練習喔！') }}
        <br />
        {{ $t('課程影片也可以無限次重複觀看') }}
      </div>
    </div>
    <Video
      v-if="isMileStoneVideoShow"
      video-id="061a1663700c4607a3f55e50a39c5458"
      mode="complete"
      class="video position-absolute"
    >
      <div class="back-btn-overlay">
        <div class="back-btn icon-Chevron-left" @click="closeVideo"></div>
      </div>
    </Video>
  </div>
</template>

<script>
import Video from '@/components/Video/Video.vue';

export default {
  components: {
    Video,
  },
  data() {
    return {
      isMileStoneVideoShow: false,
    };
  },
  computed: {
    coverUrl() {
      return this.$store.state.verification.milestoneVideoCover;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeVideo() {
      this.isMileStoneVideoShow = false;
      this.$store.commit('env/setIsLoading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.milestone {
  position: absolute;
  z-index: 2000;
  top: 0;
  .close-btn {
    text-decoration: none;
    right: 22px;
    top: 22px;
    z-index: 3;
  }
  .content {
    top: 0;
  }
  .video-wrap {
    border-radius: 10px;
    height: calc((100vw - 32px) * 0.5625);
  }
  .video-bg {
    border-radius: 10px;
    cursor: pointer;
    background: #868686;
  }
  .video-mask {
    background: rgba(0, 0, 0, 0.5);
  }
  .video-cover {
    object-fit: cover;
  }
  .video-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 38px;
  }
  .inform {
    border-radius: 10px;
    background: #ff855e;
  }
  .video {
    top: 0;
    z-index: 1023;
    .back-btn-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100px;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0) 100%
      );
      .back-btn {
        z-index: 3;
        position: absolute;
        left: 16px;
        top: 16px;
        pointer-events: all;
        color: #fff;
        cursor: pointer;
        font-size: 26px;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
}
</style>
