<template>
  <modal-wrapper
    :is-modal-wrapper-show="modalShow"
    :is-show-transition="true"
    :click-to-close="false"
    @close="closeModal"
  >
    <div
      class="certification-container mx-3 position-relative"
      :style="containerStyle"
    >
      <div
        class="empty-certification position-absolute"
        :style="emptyStyle"
      ></div>
      <div v-if="showAnimation" class="animation">
        <i
          v-for="(star, index) in stars"
          :key="`${star}${index}`"
          class="star icon-Star position-absolute"
          :class="[{active: starMove}, star]"
          :style="starStyle"
        ></i>
        <template v-if="lineMove">
          <div
            v-for="(line, index) in lines"
            :key="`${line}${index}`"
            class="line-container position-absolute"
            :class="[line.name, `animation-${line.group}`]"
          >
            <div class="placeholder"></div>
            <div class="line"></div>
          </div>
        </template>
      </div>
      <certification
        v-if="$device.isMobile"
        ref="certification"
        class="certification mobile"
        :style="certificationStyle"
        :name="userData.nickName"
        :rank="currentScore.rank"
        :time="day().format('YYYY.MM.DD')"
      ></certification>

      <certification-web-version
        v-else
        ref="certification"
        class="certification web"
        :style="certificationStyle"
        :name="userData.nickName"
        :rank="currentScore.rank"
        :width="certificationWidth"
        :time="day().format('YYYY.MM.DD')"
      ></certification-web-version>

      <div
        class="icon-wrapper text-28 d-flex justify-content-between align-items-center"
        :style="iconStyle"
      >
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="download circle d-flex justify-content-center align-items-center mr-3 cursor-pointer"
            @click="downloadCertification"
          >
            <i class="icon-Download text-white"></i>
          </div>
          <div
            v-if="$device.isMobile"
            class="share circle d-flex justify-content-center align-items-center mr-3 cursor-pointer"
            @click="shareCertification"
          >
            <i class="icon-Share text-white"></i>
          </div>
          <b-button
            size="md"
            variant="primary"
            pill
            @click="onClick2CurrentScores"
            >{{ $t('查看成績單') }}</b-button
          >
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div
            class="close-btn circle d-flex justify-content-center align-items-center cursor-pointer"
            @click="closeModal"
          >
            <i class="icon-X text-white"></i>
          </div>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import Certification from '@/components/Personal/Certification.vue';
import CertificationWebVersion from '@/components/Personal/CertificationWebVersion.vue';
import day from 'dayjs';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import {toPng} from 'html-to-image';
import delay from '@/lib/base/delay.js';
import savePhoto from '@/lib/base/savePhoto.js';

export default {
  components: {
    Certification,
    CertificationWebVersion,
    ModalWrapper,
  },
  data() {
    return {
      isReceive: false,
      modalShow: false,
      day,
      toPng,
      iconStyle: {
        opacity: 0,
      },
      certificationStyle: {
        opacity: 0,
      },
      emptyStyle: {
        opacity: 0,
      },
      starStyle: {
        opacity: 0,
      },
      stars: ['left-top', 'right-top', 'right-bottom', 'left-bottom'],
      lines: [
        {name: 'top', group: 'b'},
        {name: 'bottom', group: 'b'},
        {name: 'left-left-top', group: 'b'},
        {name: 'right-right-top', group: 'b'},
        {name: 'left-left-bottom', group: 'b'},
        {name: 'right-right-bottom', group: 'b'},
        {name: 'left-top', group: 'a'},
        {name: 'right-top', group: 'a'},
        {name: 'right-bottom', group: 'a'},
        {name: 'left-bottom', group: 'a'},
      ],
      starMove: false,
      lineMove: false,
      showAnimation: true,
      certificationWidth: 400,
    };
  },
  computed: {
    currentScore() {
      return this.$store.getters['verification/currentScore'] || {};
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    screenHeight() {
      return this.$store.state.env.height;
    },
    containerStyle() {
      if (!this.$device.isMobile) {
        if ((this.screenHeight - 48 - 38 - 16) / 1.45 < 400) {
          return {
            height: '100%',
            width: (this.screenHeight - 48 - 38 - 16) / 1.45 + 'px',
          };
        } else {
          return {
            height: 'auto',
            width: '400px',
          };
        }
      } else {
        return {};
      }
    },
  },
  watch: {
    screenHeight: {
      handler() {
        if (!this.$device.isMobile) {
          if ((this.screenHeight - 48 - 38 - 16) / 1.45 < 400) {
            this.certificationWidth = (this.screenHeight - 48 - 38 - 16) / 1.45;
            this.emptyStyle.height = this.screenHeight - 48 - 38 - 16 + 'px';
          } else {
            this.certificationWidth = 400;
            this.emptyStyle.height = '580px';
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.animation();
  },

  methods: {
    async animation() {
      await delay(0);
      this.modalShow = true;

      await delay(200);
      this.emptyStyle.opacity = 1;

      await delay(200);
      this.emptyStyle.transform = 'rotateY(90deg)';
      this.emptyStyle.opacity = 0;

      await delay(200);
      this.emptyStyle.opacity = 1;
      this.certificationStyle.opacity = 1;

      await delay(100);
      this.$playSound('receiveCertification');
      this.starStyle.opacity = 1;
      this.starMove = true;
      this.lineMove = true;

      await delay(300);
      this.starStyle.opacity = 0;

      await delay(500);
      this.emptyStyle.opacity = 0;
      this.iconStyle.opacity = 1;

      this.showAnimation = false;
    },
    onClick2CurrentScores() {
      this.$router.push(`/verification/pass-scores/${this.currentScore.id}`);
    },
    async downloadCertification() {
      this.$store.commit('env/setIsLoading', true);

      try {
        const element = this.$refs.certification.$el;
        await this.toPng(element);
        const base64 = await this.toPng(element);
        savePhoto(base64, `certificate-${new Date().getTime()}.png`);

        this.$store.commit('env/setIsLoading', false);
        this.$Message.success(this.$t('證書儲存成功'), 3000);
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.$Message.error(this.$t('證書儲存失敗'), 3000);
      }
    },
    async shareCertification() {
      this.$store.commit('env/setIsLoading', true);
      const element = this.$refs.certification.$el;
      await this.toPng(element);
      const base64 = await this.toPng(element);
      setTimeout(() => {
        this.$store.commit('env/setIsLoading', false);
      }, 2000);
      await this.$JShare.shareImage(base64);
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.certification {
  transition: all 0.2s;
}
.empty-certification {
  transition: all 0.2s;
  background: #fef5e5;
  top: 0;
  left: 3px;
  width: 98%;
  height: 88.5%;
  border-radius: 5px;
}
.certificate-container {
  z-index: -1;
}
.animation {
  .star {
    transition: all 0.3s ease-in-out;
    font-size: 50px;
    z-index: 4;
    color: #ffc069;
    &.left-top {
      top: 1%;
      left: 21%;
      &.active {
        top: -9%;
        left: 11%;
      }
    }
    &.right-top {
      top: 9%;
      right: 21%;
      &.active {
        top: -6%;
        right: 5%;
      }
    }
    &.left-bottom {
      top: 85%;
      left: 21%;
      &.active {
        top: 96%;
        left: 8%;
      }
    }
    &.right-bottom {
      top: 69%;
      right: 15%;
      &.active {
        top: 88%;
        right: 4%;
      }
    }
  }
  .line-container {
    height: 80px;
    max-height: 80px;
    width: 8px;
    overflow-y: hidden;
    border-radius: 10px;
    z-index: 3;
    &.top {
      transform: rotate(180deg);
      top: -10%;
      left: 50%;
    }
    &.bottom {
      top: 85%;
      left: 50%;
    }
    &.left-top {
      transform: rotate(150deg);
      top: -12%;
      left: 18%;
    }
    &.left-left-top {
      transform: rotate(134deg);
      top: 4%;
      left: 2%;
    }
    &.right-top {
      transform: rotate(210deg);
      top: -12%;
      right: 17%;
    }
    &.right-right-top {
      transform: rotate(227deg);
      top: 3%;
      right: 1%;
    }
    &.left-bottom {
      transform: rotate(22deg);
      bottom: 8px;
      left: 17%;
    }
    &.left-left-bottom {
      transform: rotate(56deg);
      bottom: 103px;
      left: 1%;
    }
    &.right-bottom {
      transform: rotate(328deg);
      bottom: 17px;
      right: 53px;
    }
    &.right-right-bottom {
      transform: rotate(313deg);
      bottom: 111px;
      right: -6px;
    }
  }
  .placeholder {
    background-color: transparent;
    width: 100%;
    height: 0%;
    border-radius: 10px;
    animation-name: lineAnimation;
    animation-fill-mode: forwards;
  }
  .line {
    background-color: $primary;
    width: 100%;
    height: 30%;
    border-radius: 10px;
    animation-name: lineAnimation;
    animation-fill-mode: forwards;
  }
  .animation-a {
    .placeholder {
      animation-duration: 0.3s;
      animation-delay: 0.3s;
    }
    .line {
      animation-duration: 0.3s;
    }
  }
  .animation-b {
    .placeholder {
      animation-duration: 0.3s;
      animation-delay: 0.5s;
    }
    .line {
      animation-duration: 0.5s;
    }
  }
}

.icon-wrapper {
  transition: all 0.3s;
  margin-top: 12px;
  .circle {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    transition: all 0.2s;
  }
  .close-btn {
    background: $secondary;
    &:hover {
      background: #f4ae4e;
    }
  }
  .download,
  .share {
    background: $primary;
    &:hover {
      background: #e57754;
    }
  }
}

@keyframes lineAnimation {
  100% {
    height: 100%;
  }
}

@media screen and (min-width: 768px) {
  .empty-certification {
    top: 24px;
    left: 0;
    width: 100%;
  }
  .certification-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px 0;
  }
  .icon-wrapper {
    margin-top: 16px;
  }
}
</style>
