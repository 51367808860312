<template>
  <div
    class="certificate-container d-flex align-items-center justify-content-center"
    :style="{position: 'relative'}"
  >
    <!-- 下載png時所使用的圖（srcset圖片無法下載png）-->
    <img :src="require(`@/assets/img/personal/certificate.png`)" />
    <div
      class="text-wrapper d-flex flex-column align-items-center justify-content-center position-absolute"
    >
      <span
        class="logo-title d-flex align-items-center w-100 justify-content-center"
        :style="titleStyle"
      >
        {{ appName }}
      </span>
      <span
        class="stage-title font-weight-bold d-flex align-items-center w-100 justify-content-center"
        :style="rankStyle"
      >
        {{ $t('{text}棋力檢定證書', {text: rankText}) }}
      </span>
      <span
        class="username font-weight-bold d-flex align-items-center w-100 justify-content-center"
        :style="nameStyle"
      >
        {{ name }}
      </span>
      <div
        class="content d-flex flex-column align-items-center w-100"
        :style="contentStyle"
      >
        <span>{{ $t('參加棋力檢定測驗已達標準') }}</span>
        <span>{{ $t('特頒鑑定證書，以資證明') }} </span>
      </div>
      <span
        class="date d-flex align-items-center w-100 justify-content-center"
        :style="dateStyle"
      >
        {{ time }}
      </span>
    </div>
  </div>
</template>

<script>
import {appName} from '@/constant/env.js';
import ranksMapping from '@/json/rank.json';

export default {
  name: 'Certification',
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
    rank: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
      default: 400,
    },
  },
  data() {
    return {
      appName,
    };
  },
  computed: {
    rankText() {
      const rankMap = ranksMapping[this.rank].label;
      return rankMap.replace('d', this.$t('段')).replace('k', this.$t('級'));
    },
    titleStyle() {
      return {
        fontSize: this.width / 25.6095 + 'px',
        marginTop: this.width / 5.6489 + 'px',
        lineHeight: this.width / 19.0476 + 'px',
      };
    },
    rankStyle() {
      return {
        fontSize: this.width / 14.4054 + 'px',
        marginTop: this.width / 22.3589 + 'px',
        lineHeight: this.width / 8.5106 + 'px',
      };
    },
    nameStyle() {
      return {
        fontSize: this.width / 14.4054 + 'px',
        marginTop: this.width / 6.3371 + 'px',
        lineHeight: this.width / 8.5106 + 'px',
      };
    },
    contentStyle() {
      return {
        fontSize: this.width / 19.20712 + 'px',
        marginTop: this.width / 9.1596 + 'px',
        lineHeight: this.width / 10.2564 + 'px',
      };
    },
    dateStyle() {
      return {
        fontSize: this.width / 25.6095 + 'px',
        marginTop: this.width / 36.7309 + 'px',
        lineHeight: this.width / 14.2857 + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.certificate-container {
  height: calc(100% - 50px);
  img {
    top: 0;
    width: 100%;
  }
  .text-wrapper {
    top: 0;
    z-index: 1;
    .stage-title {
      color: #bc8954;
    }
  }
}
</style>
