<template>
  <modal-wrapper
    :is-modal-wrapper-show="true"
    :click-to-close="false"
    :is-show-transition="true"
  >
    <div class="milestone position-absolute bg-white p-3 overflow-hidden">
      <div class="milestone-container w-100 h-100">
        <i
          class="icon-X close-btn position-absolute text-32 cursor-pointer"
          @click.prevent="close"
        ></i>

        <img
          v-if="$device.isMobile"
          class="img-fluid"
          :srcset="require('@/assets/img/verification/bg-milestone.png')"
          alt=""
        />
        <div
          class="content h-100 px-3 py-4 position-absolute d-flex flex-column justify-content-center"
        >
          <p class="text-20 text-center font-weight-bold">
            {{ $t('恭喜你完成所有課程！') }}
            <br />
            {{ $t('立即觀賞完課特輯') }}
          </p>
          <img
            v-if="$device.isMobile"
            class="img-fluid"
            :srcset="require('@/assets/img/verification/cheer.png?srcset')"
          />
          <div class="video-wrap bg-white p-0">
            <div
              class="video-bg position-relative h-100 overflow-hidden d-flex align-items-center justify-content-center"
              @click="isMileStoneVideoShow = true"
            >
              <div class="video-mask position-absolute w-100 h-100"></div>
              <img
                v-if="coverUrl"
                class="video-cover img-fluid w-100 h-100"
                :src="coverUrl"
                alt=""
              />
              <div
                class="video-btn position-absolute bg-primary text-white d-flex align-items-center justify-content-center"
              >
                <i class="icon-Play"></i>
              </div>
            </div>
          </div>
          <div
            class="inform bg-primary text-white text-center p-2 font-weight-bold"
          >
            {{ $t('完課了還是可以自己練習喔！') }}
            <br />
            {{ $t('課程影片也可以無限次重複觀看') }}
          </div>
        </div>
        <Video
          v-if="isMileStoneVideoShow"
          video-id="061a1663700c4607a3f55e50a39c5458"
          mode="complete"
          class="video position-fixed"
        >
          <div class="back-btn-overlay">
            <div class="back-btn icon-Chevron-left" @click="closeVideo"></div>
          </div>
        </Video>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import Video from '@/components/Video/Video.vue';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';

export default {
  components: {
    Video,
    ModalWrapper,
  },
  data() {
    return {
      isMileStoneVideoShow: false,
    };
  },
  computed: {
    coverUrl() {
      return this.$store.state.verification.milestoneVideoCover;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeVideo() {
      this.isMileStoneVideoShow = false;
      this.$store.commit('env/setIsLoading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.milestone {
  width: 900px;
  height: 600px;
  border-radius: 10px;
  top: calc(50% - 300px);
  z-index: 2000;
  .milestone-container {
    border-radius: 10px;
    background: $bgsection;
  }
  .close-btn {
    color: $primary;
    text-decoration: none;
    right: 22px;
    top: 22px;
    z-index: 3;
  }
  .content {
    top: 0;
    width: calc(100% - 32px);
    color: black;
    p {
      color: black;
      margin-bottom: 16px;
    }
  }
  .video-wrap {
    border-radius: 10px;
    margin: 0 auto;
    width: 650px;
    height: 350px;
  }
  .video-bg {
    border-radius: 10px;
    cursor: pointer;
    background: #868686;
  }
  .video-mask {
    background: rgba(0, 0, 0, 0.5);
  }
  .video-cover {
    object-fit: cover;
  }
  .video-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 38px;
  }
  .inform {
    border-radius: 10px;
    width: 650px;
    margin: 16px auto 0;
    border-radius: 10px;
  }
  .video {
    top: 0;
    left: 0;
    z-index: 1023;
    .back-btn-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100px;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0) 100%
      );
      .back-btn {
        z-index: 3;
        position: absolute;
        left: 16px;
        top: 16px;
        pointer-events: all;
        color: #fff;
        cursor: pointer;
        font-size: 26px;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
}
</style>
